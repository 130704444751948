<template>
  <div class="h-auto p-3 pb-10">
    <nav class="flex flex-col" aria-label="Breadcrumb" id="navSection">
      <ol class="inline-flex items-center space-x-1 md:space-x-3">
        <li class="inline-flex items-center">
          <router-link
            to="/"
            class="inline-flex items-center text-sm font-medium text-gray-700 hover:text-gray-900 dark:text-gray-400 dark:hover:text-white"
          >
            <svg
              class="mr-2 w-4 h-4"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"
              ></path>
            </svg>
            Home
          </router-link>
        </li>
        <li>
          <div class="flex items-center">
            <svg
              class="w-6 h-6 text-gray-400"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                clip-rule="evenodd"
              ></path>
            </svg>
            <router-link
              to="#"
              class="ml-1 text-sm font-medium text-gray-700 hover:text-gray-900 md:ml-2 dark:text-gray-400 dark:hover:text-white"
              >News | Edit News
            </router-link>
          </div>
        </li>
      </ol>

      <div class="mt-5 w-full">
        <h1 class="text-2xl text-gray-900 font-medium dark:text-gray-200">
          Edit News
        </h1>
        <p class="mt-1 text-sm font-normal text-gray-400">
          update current news or event.
        </p>
      </div>
    </nav>

    <div
      class="bg-white dark:bg-gray-800 rounded-md p-5 w-full box-border border dark:border-gray-700 mt-5"
    >
      <form @submit.prevent="updateNews" v-show="!isLoading">
        <div class="informations flex flex-col gap-4 mb-5">
          <Input
            type="text"
            placeholder="Title"
            v-model="updateNewsViewModel.title"
            :isRequired="true"
          ></Input>

          <FilterBox id="categorySelect" title="Category">
            <template v-slot:title>
              <span>Category</span>
            </template>
            <template v-slot:body>
              <div
                v-for="(item, index) in categories"
                :key="item.id"
                class="flex justify-between mb-6 items-center text-black dark:text-white"
                :class="
                  index !== categories.length - 1
                    ? 'pb-3 mb-3 border-b-2 dark:border-gray-700'
                    : ''
                "
              >
                <div class="flex items-center gap-3">
                  <input
                    v-model="updateNewsViewModel.categoryId"
                    class="relative float-left h-5 w-5 appearance-none rounded-full border-2 border-solid border-gray-400 before:pointer-events-none before:absolute before:h-4 before:w-4 before:scale-0 before:rounded-full before:bg-transparent before:opacity-0 before:shadow-[0px_0px_0px_8px_transparent] before:content-[''] after:absolute after:z-[1] after:block after:h-4 after:w-4 after:rounded-full after:content-[''] checked:border-primary checked:before:opacity-[0.16] checked:after:absolute checked:after:left-1/2 checked:after:top-1/2 checked:after:h-[0.625rem] checked:after:w-[0.625rem] checked:after:rounded-full checked:after:border-primary checked:after:bg-primary checked:after:content-[''] checked:after:[transform:translate(-50%,-50%)] hover:cursor-pointer hover:before:opacity-[0.04] hover:before:shadow-[0px_0px_0px_8px_rgba(0,0,0,0.6)] focus:shadow-none focus:outline-none focus:ring-0 focus:before:scale-100 focus:before:opacity-[0.12] focus:before:shadow-[0px_0px_0px_8px_rgba(0,0,0,0.6)] focus:before:transition-[box-shadow_0.2s,transform_0.2s] checked:focus:border-primary checked:focus:before:scale-100 checked:focus:before:shadow-[0px_0px_0px_8px_#3b71ca] checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] dark:border-gray-500 dark:checked:border-primary dark:checked:after:border-primary dark:checked:after:bg-primary dark:focus:before:shadow-[0px_0px_0px_8px_rgba(255,255,255,0.4)] dark:checked:focus:border-primary dark:checked:focus:before:shadow-[0px_0px_0px_8px_#3b71ca]"
                    type="radio"
                    :value="item.id"
                    name="countryRadio"
                  />

                  <input
                    type="text"
                    v-model="item.name"
                    :disabled="!item.editableFilterItems"
                    class="text-black text-sm dark:text-white bg-gray-100 placeholder:text-gray-400 dark:placeholder:text-gray-500 dark:bg-gray-900 outline-none"
                  />
                </div>

                <div class="flex relative h-full items-center">
                  <div
                    :class="
                      !item.editableFilterItems
                        ? 'flex items-center gap-2'
                        : 'hidden'
                    "
                  >
                    <button
                      class="text-gray-600 dark:text-gray-400 text-lg"
                      @click="() => deleteItem(item.id, 'category')"
                      type="button"
                    >
                      <Icon icon="tabler:trash-filled" />
                    </button>
                    <button
                      @click="() => editableItems(item)"
                      type="button"
                      class="text-gray-600 dark:text-gray-400 text-lg"
                    >
                      <Icon icon="ri:ball-pen-fill" />
                    </button>
                  </div>

                  <!-- Confirm change buttons -->
                  <div
                    :class="
                      item.editableFilterItems
                        ? 'flex items-center gap-2'
                        : 'hidden'
                    "
                  >
                    <button
                      @click="() => (item.editableFilterItems = false)"
                      class="text-gray-600 dark:text-gray-400 text-lg"
                      type="button"
                    >
                      <Icon icon="heroicons:x-mark-20-solid" />
                    </button>
                    <button
                      @click="() => editItem(item, 'category')"
                      type="button"
                      class="text-gray-600 dark:text-gray-400 text-lg"
                    >
                      <Icon icon="material-symbols:check" />
                    </button>
                  </div>
                </div>
              </div>

              <div class="relative flex">
                <input
                  type="text"
                  placeholder="Add New Category"
                  v-model="this.newCategoryValue"
                  class="p-2 w-full text-sm text-black dark:text-white border-gray-300 placeholder:text-gray-400 dark:placeholder:text-gray-500 border-2 bg-gray-100 dark:bg-gray-900 dark:border-gray-700 outline-none"
                />
                <button
                  @click="() => addItem(this.newCategoryValue, 'category')"
                  class="border-2 border-l-0 p-2 border-gray-300 dark:border-gray-600 text-xl text-gray-600 dark:text-gray-400"
                  data-te-ripple-init
                  type="button"
                  id="button-addon1"
                >
                  <Icon icon="ic:baseline-plus" />
                </button>
              </div>
            </template>
          </FilterBox>

          <Input
            type="textarea"
            placeholder="Preview Text"
            v-model="updateNewsViewModel.previewText"
            :isRequired="true"
          ></Input>

          <div class="flex flex-col gap-2 w-full">
            <h6 class="font-medium text-sm text-gray-600 dark:text-gray-400">
              Cover Image
            </h6>
            <FileUploader
              ref="coverImageFileUploader"
              @updatePendingFiles="updatePendingFiles($event, 'coverImage')"
            ></FileUploader>
          </div>

          <!-- body section -->
          <!-- <Input
              type="textarea"
              placeholder="Body"
              v-model="updateNewsViewModel.body"
              :isRequired="true"
            ></Input> -->
          <div class="flex flex-col gap-2">
            <h6 class="font-medium text-sm text-gray-600 dark:text-gray-400">
              Body
            </h6>
            <TextEditor
              title="Body"
              ref="textEditor"
              @valueChanged="($event) => (updateNewsViewModel.body = $event)"
            ></TextEditor>
          </div>

          <!-- <div class="flex flex-col justify-center items-start gap-3">
              <Input
                type="number"
                placeholder="Ordering"
                v-model="addProject.ordering"
              ></Input>
  
              <span class="italic text-sm text-black dark:text-white"
                >* Notice: The default ordering value is 10,000 so that you can create a
                different order between each project.</span
              >
            </div> -->
        </div>
        <hr class="w-full border dark:border-gray-700" />
        <div class="wrapper-button flex justify-end mt-5">
          <button
            v-if="!isAdding"
            type="submit"
            class="text-white bg-primary rounded-md hover:bg-primary/80 transition-all duration-300 focus:outline-none py-2 px-6 w-fit"
          >
            Save
          </button>

          <button
            v-else
            disabled
            type="button"
            class="text-white bg-primary rounded-md opacity-60 cursor-default transition-all duration-300 focus:outline-none py-2 px-6 w-fit"
          >
            <svg
              role="status"
              class="inline w-4 h-4 mr-1 text-white animate-spin"
              viewBox="0 0 100 101"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                fill="#E5E7EB"
              />
              <path
                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                fill="currentColor"
              />
            </svg>
            Uploading...
          </button>
        </div>
      </form>
    </div>

    <div
      class="flex justify-center items-center w-full h-full p-16 pt-[140px]"
      v-if="isLoading"
    >
      <LoadingSpinner class="text-logicaTertiary dark:text-logicaSecondary" />
    </div>
  </div>
</template>

<script>
import { Icon } from "@iconify/vue";
import Input from "@/components/Input.vue";
import FilterBox from "@/components/FilterBox.vue";
import FileUploader from "@/components/FileUploader.vue";
import TextEditor from "@/components/TextEditor.vue";
import axios from "axios";
import settings from "@/settings/appSettings";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import router from '@/router';

export default {
  components: {
    Icon,
    FileUploader,
    Input,
    FilterBox,
    TextEditor,
    LoadingSpinner,
  },

  data() {
    return {
      isLoading: true,
      categories: null,
      updateNewsViewModel: {
        id: null,
        title: "",
        body: "",
        readArticleUrl: "",
        previewText: "",
        categoryId: null,
        coverImageFile: null,
        // ordering: 10000,
      },
      newCategoryValue: "",
      isAdding: false,
    };
  },

  async mounted() {
    this.updateNewsViewModel.id = this.$route.params.id;

    await this.getNews(this.updateNewsViewModel.id);

    await this.getCategories();

    this.$refs.textEditor.content = this.updateNewsViewModel.body;
  },

  methods: {
    updatePendingFiles: function (files, imageType, sliderIndex = 0) {
      console.log(files);
      if (files) {
        if (imageType == "coverImage") {
          this.updateNewsViewModel.coverImageFile = files[0]?.file;
        }
      }
    },

    editableItems(item) {
      item.editableFilterItems = !item.editableFilterItems;
    },

    async updateNews() {
      if (!this.updateNewsViewModel.coverImageFile) {
        this.$store.commit("showToast", {
          id: "myToast",
          type: "error",
          title: "Cover Image Is Required!",
          body: "You must choice any image for cover image!",
        });
        return;
      }

      if (
        this.updateNewsViewModel.body == "" ||
        this.updateNewsViewModel.body.includes("empty")
      ) {
        this.$store.commit("showToast", {
          id: "myToast",
          type: "error",
          title: "Body Is Required!",
          body: "You must fill body!",
        });
        return;
      }

      let options = {
        isNeedJwtAuth: true,
      };

      this.isAdding = true;

      await axios
        .put("blogs", this.updateNewsViewModel, options)
        .then(async (response) => {
          await this.uploadImage(
            this.updateNewsViewModel.coverImageFile,
            this.updateNewsViewModel.id,
            "CoverImageFile",
            "Blogs/UpdateBlogCoverImage"
          );

          window.scrollTo(0, 0);

          this.$store.commit("showToast", {
            id: "myToast",
            type: "success",
            title: "Add Successful!",
            body: "The adition was successful!",
          });
          this.isAdding = false;
        })
        .catch((error) => {
          console.log(error);
          console.log(error.response);
          if (error.response?.data?.isSuccess == false) {
            this.$store.commit("showToast", {
              id: "myToast",
              type: "error",
              title: "Add Failed!",
              body: error.response?.data?.messages[0],
            });
          }
          this.isAdding = false;
        });
    },

    async uploadImage(imageFile, id, key, url) {
      var bodyFormData = new FormData();

      if (!imageFile) {
        return;
      }

      bodyFormData.append("BlogId", id);

      bodyFormData.append(key, imageFile, imageFile.name);

      let options = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        isNeedJwtAuth: true,
      };

      await axios
        .put(url, bodyFormData, options)
        .then((response) => {})
        .catch((error) => {
          console.log(error);
        });
    },

    async getCategories() {
      console.log("getCategories");
      await axios
        .get("Blogs/GetBlogCategories")
        .then((response) => {
          this.categories = response.data?.value;
        })
        .catch((error) => {
          console.log(error);
          console.log(error.response);
        });
    },
    async deleteItem(id, type) {
      if (type == "category") {
        await this.deleteCategory(id);
      }
    },
    async editItem(item, type) {
      if (type == "category") {
        await this.updateCategory(item);
      }
    },
    async addItem(newValue, type) {
      if (type == "category") {
        await this.addCategory(newValue);
      }
    },

    async deleteCategory(id) {
      await axios
        .delete(`Blogs/RemoveCategory/${id}`, { isNeedJwtAuth: true })
        .then(async (response) => {
          await this.getCategories();
        })
        .catch((error) => {
          if (!error.response?.data?.isSuccess) {
            console.log(error.response?.data?.messages[0]);
            return;
          }
          console.log(error);
          console.log(error.response);
        });
    },
    async updateCategory(item) {
      await axios
        .put(
          `Blogs/UpdateCategory`,
          { categoryId: item.id, newCategoryName: item.name },
          { isNeedJwtAuth: true }
        )
        .then(async (response) => {
          item.editableFilterItems = false;
        })
        .catch((error) => {
          if (!error.response?.data?.isSuccess) {
            console.log(error.response?.data?.messages[0]);
            return;
          }
          console.log(error);
          console.log(error.response);
        });
    },
    async addCategory(newValue) {
      await axios
        .post("Blogs/AddCategory", { title: newValue }, { isNeedJwtAuth: true })
        .then(async (response) => {
          await this.getCategories();
        })
        .catch((error) => {
          console.log(error);
          console.log(error.response);
        });
    },

    async getNews(id) {
      await axios
        .get(`Blogs/${id}`)
        .then((response) => {
          this.updateNewsViewModel = response.data?.value;

          this.updateNewsViewModel.id = id;

          this.updateNewsViewModel.categoryId =
            response.data?.value?.categoryInfo?.id;

          if (response.data?.value.coverImageUrl) {
            this.$refs.coverImageFileUploader.refPound.addFile(
              `${settings.backendBaseUrl}/${response.data?.value.coverImageUrl}?_`
            );
          }

          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;

          if (error.response?.data?.isSuccess == false) {
            this.$store.commit("showToast", {
              id: "myToast",
              type: "error",
              title: "Error!",
              body: error.response.data.messages[0],
            });
          } else {
            this.$store.commit("showToast", {
              id: "myToast",
              type: "error",
              title: "Error!",
              body: "An unknown error occurred",
            });
          }

          router.push("/");
          console.log(error);
          console.log(error.response);
        });
    },
  },
};
</script>
