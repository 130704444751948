<template>
  <div class="news-list h-auto p-3 pb-10">
    <nav class="flex flex-col" aria-label="Breadcrumb">
      <ol class="inline-flex items-center space-x-1 md:space-x-3">
        <li class="inline-flex items-center">
          <router-link
            to="/"
            class="inline-flex items-center text-sm font-medium text-gray-700 hover:text-gray-900 dark:text-gray-400 dark:hover:text-white"
          >
            <svg
              class="mr-2 w-4 h-4"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"
              ></path>
            </svg>
            Home
          </router-link>
        </li>
        <li>
          <div class="flex items-center">
            <svg
              class="w-6 h-6 text-gray-400"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                clip-rule="evenodd"
              ></path>
            </svg>
            <router-link
              to="/news"
              class="ml-1 text-sm font-medium text-gray-700 hover:text-gray-900 md:ml-2 dark:text-gray-400 dark:hover:text-white"
              >News List</router-link
            >
          </div>
        </li>
      </ol>

      <div class="mt-5 w-full">
        <h1 class="text-2xl text-gray-900 font-medium dark:text-gray-200">
          News List
        </h1>
        <p class="mt-1 text-sm font-normal text-gray-400">
          View all news and events.
        </p>
      </div>
    </nav>

    <div
      class="card flex flex-wrap gap-x-6 max-[1100px]:gap-y-3 mt-5 justify-between bg-white dark:bg-gray-800 w-full rounded-md p-3 border dark:border-gray-700"
    >
      <div class="flex max-sm:flex-col gap-y-3 gap-x-2 max-[1100px]:w-full">
        <div class="w-max max-[1100px]:w-1/2 max-sm:w-full">
          <dropdown placement="left">
            <template v-slot:button>
              <button
                class="flex justify-between w-full px-4 py-3 rounded-md border bg-gray-100 dark:bg-gray-900 text-sm dark:border-gray-700"
              >
                <div>
                  <span class="text-gray-500 dark:text-gray-400 mr-1"
                    >Category</span
                  >
                </div>
                <span class="ml-2 mt-1 text-gray-500"
                  ><Icon icon="ant-design:caret-down-filled"
                /></span>
              </button>
            </template>
            <template v-slot:content>
              <div>
                <a
                  v-for="(item, index) in allCategories"
                  @click="() => categoryChange(item.id)"
                  href="#"
                  class="block hover:bg-primary hover:text-white px-4 py-2"
                  >{{ item.name }}</a
                >
                <a
                  @click="() => categoryChange(null)"
                  href="#"
                  class="block hover:bg-primary hover:text-white px-4 py-2"
                  >Uncategorized</a
                >
              </div>
            </template>
          </dropdown>
        </div>

        <div class="w-max max-[1100px]:w-1/2 max-sm:w-full">
          <dropdown placement="left">
            <template v-slot:button>
              <button
                class="flex justify-between w-full px-4 py-3 rounded-md border bg-gray-100 dark:bg-gray-900 text-sm dark:border-gray-700"
              >
                <div class="flex">
                  <span class="text-gray-500 dark:text-gray-400 mr-1"
                    >Filter</span
                  >
                </div>
                <span class="ml-2 mt-1 text-gray-500"
                  ><Icon icon="ant-design:caret-down-filled"
                /></span>
              </button>
            </template>
            <template v-slot:content>
              <div>
                <a
                  @click="() => filterChange('CreatedDateDec')"
                  href="#"
                  class="block hover:bg-primary hover:text-white px-4 py-2"
                  >Newest</a
                >
                <a
                  @click="() => filterChange('CreatedDate')"
                  href="#"
                  class="block hover:bg-primary hover:text-white px-4 py-2"
                  >Oldest</a
                >
                <a
                  @click="() => filterChange('UpdatedDateDec')"
                  href="#"
                  class="block hover:bg-primary hover:text-white px-4 py-2"
                  >Last Updated</a
                >
              </div>
            </template>
          </dropdown>
        </div>
      </div>

      <div
        class="input-box border bg-gray-100 dark:bg-gray-900 dark:border-gray-700 rounded-md w-fit max-[1100px]:w-full box-border flex focus-within:bg-gray-100 dark:focus-within:bg-gray-700"
      >
        <button type="button" class="text-3xl p-2 text-gray-400">
          <Icon icon="ei:search" />
        </button>
        <input
          v-model="searchContent"
          type="text"
          placeholder="Search..."
          class="p-3 w-full bg-gray-100 dark:bg-gray-900 dark:text-gray-400 rounded-md outline-none focus:bg-gray-100 dark:focus:bg-gray-700"
        />
      </div>
    </div>

    <div
      v-if="!isLoading"
      class="grid min-[2100px]:grid-cols-4 min-[1600px]:grid-cols-3 xl:grid-cols-3 lg:grid-cols-2 min-[880px]:grid-cols-3 min-[600px]:grid-cols-2 mt-9 gap-5"
    >
      <Box
        v-for="(item, index) in newsBox"
        :key="index"
        :title="item.title"
        :date="item.createdDate"
        :text="item.previewText"
        :image="item.coverImageUrl"
      >
        <template v-slot:buttons>
          <button type="button" @click="removeItemClick(item.id)">
            <Icon icon="tabler:trash-filled" />
          </button>
          <router-link :to="`/news/edit/${item.id}#navSection`">
            <Icon icon="ri:ball-pen-fill" />
          </router-link>
        </template>
      </Box>
    </div>

    <div
      v-if="newsBox.length === 0 && !isLoading"
      class="w-full h-full flex justify-center items-center mt-20"
    >
      <span class="dark:text-white text-black">News list is empty</span>
    </div>

    <div
      class="flex justify-center items-center w-full h-full p-16 pt-[140px]"
      v-if="isLoading"
    >
      <LoadingSpinner class="text-logicaTertiary dark:text-logicaSecondary" />
    </div>

    <div
      class="w-full flex justify-center mt-10 max-sm:mt-8"
      v-if="pagination.hasNext && !isLoading"
    >
      <div
        class="w-full max-w-[300px] max-sm:max-w-[200px] flex flex-col justi items-center gap-3"
      >
        <hr
          class="w-full h-1 max-sm:h-[2px] rounded-lg bg-primary mt-5 border-none"
        />
        <button
          @click="showMore"
          type="button"
          class="flex flex-col justify-center items-center"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            version="1.1"
            class="fill-jestaBrown w-16 max-sm:w-10 h-16 max-sm:h-10"
            viewBox="0 0 256 256"
            xml:space="preserve"
          >
            <defs></defs>
            <g
              style="
                stroke: none;
                stroke-width: 0;
                stroke-dasharray: none;
                stroke-linecap: butt;
                stroke-linejoin: miter;
                stroke-miterlimit: 10;
                fill: none;
                fill-rule: nonzero;
                opacity: 1;
              "
              transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)"
            >
              <path
                d="M 90 23.25 c 0 -0.384 -0.146 -0.768 -0.439 -1.061 c -0.586 -0.586 -1.535 -0.586 -2.121 0 L 45 64.629 L 2.561 22.189 c -0.585 -0.586 -1.536 -0.586 -2.121 0 c -0.586 0.586 -0.586 1.535 0 2.121 l 43.5 43.5 c 0.585 0.586 1.536 0.586 2.121 0 l 43.5 -43.5 C 89.854 24.018 90 23.634 90 23.25 z"
                style="
                  stroke: none;
                  stroke-width: 1;
                  stroke-dasharray: none;
                  stroke-linecap: butt;
                  stroke-linejoin: miter;
                  stroke-miterlimit: 10;
                  fill: rgb(79 70 229);
                  fill-rule: nonzero;
                  opacity: 1;
                "
                transform=" matrix(1 0 0 1 0 0) "
                stroke-linecap="round"
              />
            </g>
          </svg>

          <span
            class="uppercase text-primary font-semibold text-base max-sm:text-sm tracking-[0px]"
            >Show More</span
          >
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Dropdown from "../../components/Dropdown.vue";
import Box from "@/components/Box.vue";
import axios from "axios";
import { Icon } from "@iconify/vue";
import appSettings from "@/settings/appSettings";
import LoadingSpinner from "@/components/LoadingSpinner.vue";

export default {
  components: {
    Icon,
    Dropdown,
    Box,
    LoadingSpinner,
  },
  data() {
    return {
      newsBox: [],
      isLoading: true,
      pagination: {},
      categoryId: null,
      filter: null,
      searchContent: null,
      allCategories: [],
    };
  },

  async created() {
    await this.getAllCategories();
    await this.getAllNews();
  },

  methods: {
    getAllNews: async function (
      categoryId = null,
      filter = null,
      searchContent = null,
      currentPage = null
    ) {
      if (!currentPage || searchContent) {
        this.isLoading = true;
        this.newsBox = [];
      }

      let url = `Blogs/GetBlogsBasicInformation?PageSize=12`;

      if (categoryId) {
        url += `&CategoryId=${categoryId}`;
      }

      if (filter) {
        url += `&BlogOrderEnum=${filter}`;
      }

      if (searchContent) {
        url += `&SearchValue=${searchContent}`;
      }

      if (currentPage) {
        url += `&PageNumber=${currentPage}`;
      }

      await axios
        .get(url)
        .then((res) => {
          res.data?.value.forEach((element) => {
            element.coverImageUrl = `${appSettings.backendBaseUrl}/${element.coverImageUrl}`;
          });

          if (currentPage) {
            this.newsBox = this.newsBox.concat(res.data?.value);
          } else {
            this.newsBox = res.data?.value;
          }

          this.pagination = res.data?.pagination;

          this.isLoading = false;
        })
        .catch((err) => {
          this.pagination = {};
          this.isLoading = false;
          console.log(err);
        });
    },
    async getAllCategories() {
      await axios
        .get(`Blogs/GetAlreadyBlogCategories`)
        .then((response) => {
          this.allCategories = response.data?.value;
        })
        .catch((error) => {
          console.log(error);
          console.log(error.response);
        });
    },
    async categoryChange(categoryId) {
      this.categoryId = categoryId;
      await this.getAllNews(categoryId);
    },
    async filterChange(filter) {
      this.filter = filter;
      await this.getAllNews(null, filter);
    },
    async removeItemClick(itemId) {
      this.isLoading = true;

      await axios
        .delete(`Blogs/${itemId}`, { isNeedJwtAuth: true })
        .then(async (response) => {
          await this.getAllNews();
        })
        .catch((error) => {
          console.log(error);
          console.log(error.response);
          if (error.response?.data?.isSuccess == false) {
            this.$store.commit("showToast", {
              id: "myToast",
              type: "error",
              title: "Remove Failed!",
              body: error.response?.data?.messages[0],
            });
          }
          this.isLoading = false;
        });
    },
    async showMore() {
      await this.getAllNews(
        this.categoryId,
        this.filter,
        null,
        ++this.pagination.currentPage
      );
    },
  },
  watch: {
    searchContent(val) {
      this.searchContent = val;

      if (this.searchContent) {
        window.scrollTo(0, 0);

        this.pagination.currentPage = 0;

        this.getAllNews(
          null,
          null,
          this.searchContent,
          ++this.pagination.currentPage
        );
      } else {
        window.scrollTo(0, 0);

        this.getAllNews();
      }
    },
  },
};
</script>
