<template>
  <div class="w-full h-screen [@media_screen_and_(max-height:670px)]:h-full">
    <div class="w-full flex justify-center items-center h-full">
      <form
        @submit.prevent="login"
        class="bg-white/40 dark:bg-gray-700/40 shadow-lg rounded-3xl backdrop-blur-lg form-body sm:max-w-md max-sm:mx-3 my-10 p-8 space-y-8"
      >
        <div class="space-y-8">
          <div class="space-y-3">
            <h2
              class="dark:text-white font-semibold text-center text-gray-800 text-4xl"
            >
              Welcome, to
            </h2>

            <div class="form-head cursor-pointer" >
              <img
                src="@/assets/logo/logica-log-dark.png"
                alt="logo"
                class="w-full hidden dark:block"
              />
              <img
                src="@/assets/logo/logica-log-light.png"
                alt="logo"
                class="w-full block dark:hidden"
              />
            </div>
          </div>

          <p class="dark:text-gray-400 text-gray-700">
            Please enter your account to continue.
          </p>
        </div>
        <div class="space-y-5">
          <div class="relative z-0 w-full mb-6 group">
            <input
              v-model="username"
              name="floating_email"
              id="floating_email"
              autocomplete="off"
              class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-[#43045e] focus:outline-none focus:ring-0 focus:border-[#674aff] peer"
              placeholder=" "
              required
            />
            <label
              for="floating_email"
              class="peer-focus:font-medium  absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-[#674aff] dark:peer-focus:text-[#7e12ad] peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
              >Username</label
            >
          </div>
          <div class="relative z-0 w-full mb-6 group">
            <input
              v-model="password"
              autocomplete="off"
              type="password"
              name="floating_password"
              id="floating_password"
              class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-[#43045e] focus:outline-none focus:ring-0 focus:border-[#674aff] peer"
              placeholder=" "
              required
            />
            <label
              for="floating_password"
              class="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-[#674aff] dark:peer-focus:text-[#7e12ad] peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
              >Password</label
            >
          </div>
        </div>

        <button
          class="!mt-14 text-white bg-[#674aff] dark:bg-[#43045e] hover:bg-[#674aff]/80 dark:hover:bg-[#43045e]/80 p-3 w-full rounded-md"
        >
          Login, to continue
        </button>
      </form>
    </div>
  </div>
</template>

<script>
import { useUserSignData } from "@/store/userSignData";
import router from "@/router";

export default {
  setup() {
    const userSignData = useUserSignData();

    if (userSignData.isAuthenticated) {
      router.push("/");
    }
  },
  data() {
    return {
      username: "",
      password: "",
      isRemember: false,
    };
  },
  methods: {
    login: async function () {
      const userSignData = useUserSignData();

      userSignData.login(this.username, this.password);
    },
  },
};
</script>
