<template>
  <!-- Contact box -->
  <div
    :id="id"
    class="card w-full h-full p-5 bg-gray-100 rounded-md border-gray-200 border dark:bg-gray-800 dark:border-gray-700"
    :class="hasRead ? 'bg-gray-200/70 border-gray-300/70 dark:bg-gray-800/50 dark:border-gray-700/50' : ''"
  >
    <p
      class="mb-4 line-clamp-2 font-normal text-gray-700 dark:text-gray-400"
      :class="hasRead ? 'text-gray-700/50 dark:text-gray-400/40' : ''"
    >
      {{ question }}
    </p>
    <ul
      class="list-[disclosure-closed] font-medium text-gray-700 dark:text-gray-400 marker:text-md space-y-1 marker:text-primary text-sm mb-3 list-inside pl-2"
      :class="hasRead ? 'text-gray-700/50 dark:text-gray-400/40 marker:text-primary/50 dark:marker:text-primary/50' : ''"
    >
      <li class="space-x-1">
        <span>{{ firstName }}</span
        ><span>{{ lastName }}</span>
      </li>
      <li>{{ email }}</li>
      <li>{{ phoneNumber }}</li>
    </ul>
    <button
      @click="openContactModal"
      type="button"
      data-te-target="#contactInfo"
      data-te-toggle="modal"
      class="inline-flex items-center text-base font-semibold text-center text-primary"
      
    >
      Read more
      <svg
        class="ml-1 -mr-1 w-4 h-4"
        fill="currentColor"
        viewBox="0 0 20 20"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
          clip-rule="evenodd"
        ></path>
      </svg>
    </button>
  </div>
</template>

<script>
export default {
  props: {
    id: {
      type: Number,
      required: true,
    },
    firstName: {
      type: String,
      required: true,
    },
    lastName: {
      type: String,
      required: true,
    },
    phoneNumber: {
      type: String,
      required: false,
    },
    email: {
      type: String,
      required: true,
    },
    question: {
      type: String,
      required: true,
    },
    hasRead: {
      type: Boolean,
      required: false,
    },
  },
  methods: {
    openContactModal() {
      this.$store.commit("addContactInfo", {
        id: this.id,
        firstName: this.firstName,
        lastName: this.lastName,
        email: this.email,
        phoneNumber: this.phoneNumber,
        question: this.question,
        hasRead: this.hasRead,
      });
    },
  },
};
</script>
