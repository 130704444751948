<template>
  <div class="w-full flex flex-col gap-2">
    <h6 class="font-medium text-sm text-gray-500 dark:text-gray-400">
      {{ title }}
    </h6>
    <div
      class="border-2 bg-gray-100 rounded-md overflow-hidden dark:bg-gray-900 dark:border-gray-700 w-full box-border"
    >
      <div class="mb-0">
        <button
          class="group relative bg-gray-100 dark:bg-gray-900 flex w-full items-center  border-0 p-3 text-left text-base text-gray-500 dark:text-gray-400 transition [overflow-anchor:none] hover:z-[2] focus:z-[3] focus:outline-none    [&:not([data-te-collapse-collapsed])]:text-jestaBrown [&:not([data-te-collapse-collapsed])]:[box-shadow:inset_0_-1px_0_rgba(229,231,235)] dark:[&:not([data-te-collapse-collapsed])]:bg-gray-900 dark:[&:not([data-te-collapse-collapsed])]:text-primary-400 dark:[&:not([data-te-collapse-collapsed])]:[box-shadow:inset_0_-1px_0_rgba(63,63,70)]"
          type="button"
          data-te-collapse-init
          data-te-collapse-collapsed
          :data-te-target="`#${id}`"
          aria-expanded="false"
          :aria-controls="id"
        >
          <slot name="title"></slot>
          <span
            class="ml-auto h-5 w-5 shrink-0 rotate-[-180deg] fill-[#336dec] transition-transform duration-200 ease-in-out group-[[data-te-collapse-collapsed]]:rotate-0 group-[[data-te-collapse-collapsed]]:fill-[#212529] motion-reduce:transition-none dark:fill-blue-300 dark:group-[[data-te-collapse-collapsed]]:fill-white"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="h-6 w-6"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M19.5 8.25l-7.5 7.5-7.5-7.5"
              />
            </svg>
          </span>
        </button>
      </div>
      <div :id="id" class="!visible hidden" data-te-collapse-item>
        <div class="px-5 py-4">
          <slot name="body"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Collapse, initTE } from "tw-elements";
export default {
  props: {
    id: {
      type: String,
      requirde: true,
    },
    title: {
      type: String,
      requirde: true,
    },
  },
  mounted() {
    initTE({ Collapse });
  },
};
</script>
