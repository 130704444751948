<template>
  <div class="wrapper-button w-full box-border">
    <div
      class="card max-w-sm shadow-lg bg-white rounded-lg border-gray-200 border dark:border-gray-700-md dark:bg-gray-800 dark:border-gray-700"
    >
      <div>
        <img
          class="rounded-t-lg min-[1800px]:h-64 min-[1600px]:h-52 max-[1350px]:h-48 lg:h-64 min-[880px]:h-56 min-[700px]:h-64 min-[600px]:h-48 h-auto w-full"
          :class="imageClass"
          :src="image"
          alt="news box image"
        />
      </div>
      <div class="p-4 pt-3 flex flex-col justify-between">
        <div class="flex flex-col">
          <span v-if="date" class="uppercase text-sm text-gray-400 mb-3">{{ date }}</span>
          <div>
            <h5
              class="mb-2 md:text-xl text-base line-clamp-1 font-bold tracking-tight text-gray-900 dark:text-white"
            >
              {{ title }}
            </h5>
          </div>
          <p class="font-normal md:text-base text-sm line-clamp-4 text-gray-700 dark:text-gray-400">
           {{ text }}
          </p>
        </div>

        <div class="mt-5 gap-5 flex justify-end items-center text-2xl text-gray-500 dark:text-gray-400">
          <slot name="buttons"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props : {
    image: {
      type: String,
      required: true
    },
    date: {
      type: String,
      required: false
    },
    title: {
      type: String,
      required: true
    },
    text: {
      type: String,
      required: false
    },
    imageClass: {
      type: String,
      required: false,
      default: 'object-cover'
    }
  }
}
</script>
