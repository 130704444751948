<template>
  <div class="new project h-auto p-3 pb-10">
    <nav class="flex flex-col" aria-label="Breadcrumb">
      <ol class="inline-flex items-center space-x-1 md:space-x-3">
        <li class="inline-flex items-center">
          <router-link
            to="/"
            class="inline-flex items-center text-sm font-medium text-gray-700 hover:text-gray-900 dark:text-gray-400 dark:hover:text-white"
          >
            <svg
              class="mr-2 w-4 h-4"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"
              ></path>
            </svg>
            Home
          </router-link>
        </li>
        <li>
          <div class="flex items-center">
            <svg
              class="w-6 h-6 text-gray-400"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                clip-rule="evenodd"
              ></path>
            </svg>
            <router-link
              to="/new/add"
              class="ml-1 text-sm font-medium text-gray-700 hover:text-gray-900 md:ml-2 dark:text-gray-400 dark:hover:text-white"
              >Yachts | Edit Yacht</router-link
            >
          </div>
        </li>
      </ol>

      <div class="mt-5 w-full">
        <h1 class="text-2xl text-gray-900 font-medium dark:text-gray-200">
          Edit Yacht
        </h1>
        <p class="mt-1 text-sm font-normal text-gray-400">
          Edit current yacht informations.
        </p>
      </div>
    </nav>

    <div
    v-show="!isLoading"
      class="bg-white dark:bg-gray-800 rounded-md p-5 w-full box-border border dark:border-gray-700 mt-5"
    >
      <form @submit.prevent="updateYacht">
        <div class="informations flex flex-col gap-6 mb-5">
          <!-- Yacht Title -->
          <div class="flex flex-col gap-2">
            <div class="flex flex-col gap-1 mb-3">
              <h3 class="text-lg text-primary font-medium">Yacht Title</h3>
              <hr
                class="border-none w-full h-[2px] bg-gray-200 dark:bg-gray-600"
              />
            </div>
            <Input
              type="text"
              placeholder="Title"
              v-model="updateYachtViewModel.title"
              :isRequired="true"
            ></Input>
          </div>

          <!-- Header Slider Section -->
          <div class="flex flex-col gap-2">
            <div class="flex flex-col gap-1 mb-3">
              <h3 class="text-lg text-primary font-medium">Header Slider</h3>
              <hr
                class="border-none w-full h-[2px] bg-gray-200 dark:bg-gray-600"
              />
            </div>
            <div>
              <div class="space-y-4">
                <div
                  class="flex flex-col gap-2"
                  v-for="(item, index) in updateYachtViewModel.yachtSliders"
                  :key="index"
                >
                  <div class="flex justify-between items-end">
                    <h6
                      class="font-medium text-sm text-gray-600 dark:text-gray-400"
                    >
                      {{ `Slider Item ${index + 1}` }}
                    </h6>

                    <button
                      v-if="updateYachtViewModel.yachtSliders?.length > 1"
                      @click="() => deleteSliderItem(index)"
                      class="text-primary text-base border border-primary rounded-full p-1"
                      type="button"
                    >
                      <Icon icon="tabler:trash-filled" />
                    </button>
                  </div>
                  <input
                    type="text"
                    class="border-2 rounded-md overflow-hidden border-gray-200 dark:border-gray-700 box-border flex p-3 w-full text-black dark:text-white bg-gray-100 placeholder:text-gray-400 dark:placeholder:text-gray-500 dark:bg-gray-900 outline-none"
                    placeholder="Slider Text"
                    v-model="
                      updateYachtViewModel.yachtSliders[index].description
                    "
                    required
                  />
                  <FileUploader
                    :ref="`yachtSliderImageFilePound${index}`"
                    @updatePendingFiles="
                      updatePendingFiles($event, 'slider', index)
                    "
                  ></FileUploader>
                </div>
              </div>

              <div class="flex mt-3 justify-center">
                <button
                  v-if="updateYachtViewModel.yachtSliders.length < 4"
                  type="button"
                  @click="addSlider"
                  class="text-2xl text-white bg-primary rounded-md hover:bg-primary/80 transition-all duration-300 focus:outline-none py-2 px-6 w-fit"
                >
                  <Icon icon="ic:outline-plus"></Icon>
                </button>
              </div>
            </div>
          </div>

          <!-- Project Section -->
          <div class="flex flex-col gap-4">
            <div class="flex flex-col gap-1 mb-1">
              <h3 class="text-lg text-primary font-medium">Project Section</h3>
              <hr
                class="border-none w-full h-[2px] bg-gray-200 dark:bg-gray-600"
              />
            </div>
            <div class="flex flex-col gap-2 w-full">
              <h6 class="font-medium text-sm text-gray-600 dark:text-gray-400">
                Project Image
              </h6>
              <FileUploader
                ref="projectImageFilePound"
                @updatePendingFiles="updatePendingFiles($event, 'projectImage')"
              ></FileUploader>
            </div>

            <div class="flex flex-col gap-2">
              <Input
                type="textarea"
                placeholder="Project Text"
                v-model="updateYachtViewModel.projectText"
                :isRequired="true"
              ></Input>
            </div>
          </div>

          <!-- Best Features Section -->
          <div class="flex flex-col gap-2">
            <div class="flex flex-col gap-1 mb-3">
              <h3 class="text-lg text-primary font-medium">Features Section</h3>
              <hr
                class="border-none w-full h-[2px] bg-gray-200 dark:bg-gray-600"
              />
            </div>

            <div class="grid xl:grid-cols-3 grid-cols-1 xl:gap-5 gap-4">
              <!-- Box 1 -->
              <div class="flex flex-col gap-2">
                <h6
                  class="font-medium text-sm text-gray-600 dark:text-gray-400"
                >
                  Box 1
                </h6>

                <div class="flex flex-col gap-2">
                  <FileUploader
                    ref="bestFeaturesBoxOneImageFilePound"
                    @updatePendingFiles="
                      updatePendingFiles($event, 'featuresBoxImage1')
                    "
                  ></FileUploader>

                  <input
                    type="text"
                    class="border-2 rounded-md overflow-hidden border-gray-200 dark:border-gray-700 box-border flex p-3 w-full text-black dark:text-white bg-gray-100 placeholder:text-gray-400 dark:placeholder:text-gray-500 dark:bg-gray-900 outline-none"
                    placeholder="Title"
                    v-model="
                      updateYachtViewModel.featuresBoxes.bestFeaturesBoxOneTitle
                    "
                    required
                  />

                  <textarea
                    class="border-2 rounded-md overflow-hidden border-gray-200 dark:border-gray-700 box-border flex p-3 w-full text-black dark:text-white bg-gray-100 placeholder:text-gray-400 dark:placeholder:text-gray-500 dark:bg-gray-900 outline-none"
                    placeholder="Description"
                    v-model="
                      updateYachtViewModel.featuresBoxes
                        .bestFeaturesBoxOneDescription
                    "
                    required
                  ></textarea>
                </div>
              </div>

              <!-- Box 2 -->
              <div class="flex flex-col gap-2">
                <h6
                  class="font-medium text-sm text-gray-600 dark:text-gray-400"
                >
                  Box 2
                </h6>

                <div class="flex flex-col gap-2">
                  <FileUploader
                    ref="bestFeaturesBoxTwoImageFilePound"
                    @updatePendingFiles="
                      updatePendingFiles($event, 'featuresBoxImage2')
                    "
                  ></FileUploader>

                  <input
                    type="text"
                    class="border-2 rounded-md overflow-hidden border-gray-200 dark:border-gray-700 box-border flex p-3 w-full text-black dark:text-white bg-gray-100 placeholder:text-gray-400 dark:placeholder:text-gray-500 dark:bg-gray-900 outline-none"
                    placeholder="Title"
                    v-model="
                      updateYachtViewModel.featuresBoxes.bestFeaturesBoxTwoTitle
                    "
                    required
                  />

                  <textarea
                    class="border-2 rounded-md overflow-hidden border-gray-200 dark:border-gray-700 box-border flex p-3 w-full text-black dark:text-white bg-gray-100 placeholder:text-gray-400 dark:placeholder:text-gray-500 dark:bg-gray-900 outline-none"
                    placeholder="Description"
                    v-model="
                      updateYachtViewModel.featuresBoxes
                        .bestFeaturesBoxTwoDescription
                    "
                    required
                  ></textarea>
                </div>
              </div>

              <!-- Box 3 -->
              <div class="flex flex-col gap-2">
                <h6
                  class="font-medium text-sm text-gray-600 dark:text-gray-400"
                >
                  Box 3
                </h6>

                <div class="flex flex-col gap-2">
                  <FileUploader
                    ref="bestFeaturesBoxThreeImageFilePound"
                    @updatePendingFiles="
                      updatePendingFiles($event, 'featuresBoxImage3')
                    "
                  ></FileUploader>

                  <input
                    type="text"
                    class="border-2 rounded-md overflow-hidden border-gray-200 dark:border-gray-700 box-border flex p-3 w-full text-black dark:text-white bg-gray-100 placeholder:text-gray-400 dark:placeholder:text-gray-500 dark:bg-gray-900 outline-none"
                    placeholder="Title"
                    v-model="
                      updateYachtViewModel.featuresBoxes
                        .bestFeaturesBoxThreeTitle
                    "
                    required
                  />

                  <textarea
                    class="border-2 rounded-md overflow-hidden border-gray-200 dark:border-gray-700 box-border flex p-3 w-full text-black dark:text-white bg-gray-100 placeholder:text-gray-400 dark:placeholder:text-gray-500 dark:bg-gray-900 outline-none"
                    placeholder="Description"
                    v-model="
                      updateYachtViewModel.featuresBoxes
                        .bestFeaturesBoxThreeDescription
                    "
                    required
                  ></textarea>
                </div>
              </div>
            </div>
          </div>

          <!-- Performance Details Section -->
          <div class="flex flex-col gap-4">
            <div class="flex flex-col gap-1 mb-1">
              <h3 class="text-lg text-primary font-medium">
                Performance Details
              </h3>
              <hr
                class="border-none w-full h-[2px] bg-gray-200 dark:bg-gray-600"
              />
            </div>

            <Input
              type="text"
              placeholder="Description"
              v-model="updateYachtViewModel.performanceDetailsDescription"
              :isRequired="true"
            ></Input>

            <div class="flex flex-col gap-4">
              <!-- Dimension -->
              <div class="flex flex-col gap-2 max-sm:gap-3">
                <div class="flex flex-col gap-2 w-full">
                  <h6
                    class="font-medium text-sm max-sm:text-base max-sm:font-semibold text-gray-600 dark:text-gray-400"
                  >
                    Dimension
                  </h6>
                  <input
                    type="text"
                    class="border-2 rounded-md overflow-hidden border-gray-200 dark:border-gray-700 box-border flex p-3 w-full text-black dark:text-white bg-gray-100 placeholder:text-gray-400 dark:placeholder:text-gray-500 dark:bg-gray-900 outline-none"
                    placeholder="Dimension Description"
                    v-model="
                      updateYachtViewModel.performaceDetails
                        .dimensionDescription
                    "
                    required
                  />
                </div>

                <div class="flex flex-col max-sm:hidden">
                  <div class="overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div class="inline-block min-w-full sm:px-6 lg:px-8">
                      <div
                        class="overflow-hidden border-2 rounded-md border-gray-200 dark:border-gray-700 box-border bg-gray-100 dark:bg-gray-900"
                      >
                        <table
                          class="min-w-full text-center text-sm font-light"
                        >
                          <thead
                            class="border-b-2 border-gray-200 dark:border-gray-700 box-border font-medium text-gray-600 dark:text-gray-400"
                          >
                            <tr>
                              <th
                                scope="col"
                                class="border-r-2 border-gray-200 dark:border-gray-700 box-border px-1 max-md:px-2"
                              ></th>
                              <th
                                scope="col"
                                class="border-r-2 border-gray-200 dark:border-gray-700 box-border"
                              >
                                Title
                              </th>
                              <th
                                scope="col"
                                class="border-r-2 border-gray-200 dark:border-gray-700 box-border"
                              >
                                Type
                              </th>
                              <th scope="col" class="px-6 py-4">Value</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              :class="{
                                'border-b-2 border-gray-200 dark:border-gray-700 box-border':
                                  index !==
                                  updateYachtViewModel.performaceDetails.dimensions?.length - 1,
                              }"
                              v-for="(
                                item, index
                              ) in updateYachtViewModel.performaceDetails.dimensions"
                            >
                              <td
                                class="whitespace-nowrap px-1 max-md:px-2 border-r-2 border-gray-200 dark:border-gray-700 box-border font-medium"
                              >
                                <button
                                  @click="
                                    () =>
                                      deletePerformanceDetailsItem(
                                        index,
                                        'dimensions'
                                      )
                                  "
                                  :class="{
                                    'text-primary/50 border-primary/50':
                                      updateYachtViewModel.performaceDetails.dimensions?.length ==
                                      1,
                                  }"
                                  :disabled="
                                    updateYachtViewModel.performaceDetails.dimensions?.length == 1
                                  "
                                  class="text-primary text-base border border-primary rounded-full p-1"
                                  type="button"
                                >
                                  <Icon icon="tabler:trash-filled" />
                                </button>
                              </td>
                              <td
                                class="whitespace-nowrap border-r-2 border-gray-200 dark:border-gray-700 box-border"
                              >
                                <input
                                  type="text"
                                  class="overflow-hidden flex px-3 py-4 w-full h-full text-black dark:text-white bg-transparent placeholder:text-gray-400 dark:placeholder:text-gray-500 outline-none"
                                  placeholder="Title..."
                                  v-model="
                                    updateYachtViewModel.performaceDetails.dimensions[index].title
                                  "
                                  required
                                />
                              </td>
                              <td
                                class="whitespace-nowrap border-r-2 border-gray-200 dark:border-gray-700 box-border"
                              >
                                <input
                                  type="text"
                                  class="overflow-hidden flex px-3 py-4 w-full h-full text-black dark:text-white bg-transparent placeholder:text-gray-400 dark:placeholder:text-gray-500 outline-none"
                                  placeholder="Type..."
                                  v-model="
                                    updateYachtViewModel.performaceDetails.dimensions[index]
                                      .value1
                                  "
                                  required
                                />
                              </td>
                              <td class="whitespace-nowrap">
                                <input
                                  type="text"
                                  class="overflow-hidden flex px-3 py-4 w-full h-full text-black dark:text-white bg-transparent placeholder:text-gray-400 dark:placeholder:text-gray-500 outline-none"
                                  placeholder="Value..."
                                  v-model="
                                    updateYachtViewModel.performaceDetails.dimensions[index]
                                      .value2
                                  "
                                  required
                                />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  class="hidden max-sm:flex flex-col gap-2"
                  v-for="(item, index) in updateYachtViewModel.performaceDetails.dimensions"
                  :key="index"
                >
                  <div class="flex justify-between items-end">
                    <h6
                      class="font-medium text-sm text-gray-600 dark:text-gray-400"
                    >
                      {{ `Table Row ${index + 1}` }}
                    </h6>

                    <button
                      v-if="updateYachtViewModel.performaceDetails.dimensions?.length > 1"
                      @click="
                        () => deletePerformanceDetailsItem(index, 'dimensions')
                      "
                      class="text-primary text-base border border-primary rounded-full p-1"
                      type="button"
                    >
                      <Icon icon="tabler:trash-filled" />
                    </button>
                  </div>
                  <div
                    class="border-2 rounded-md border-gray-200 dark:border-gray-700 box-border bg-gray-100 dark:bg-gray-900"
                  >
                    <div
                      class="whitespace-nowrap border-b-2 border-gray-200 dark:border-gray-700 box-border"
                    >
                      <input
                        type="text"
                        class="overflow-hidden flex px-3 py-4 w-full h-full text-black dark:text-white bg-transparent placeholder:text-gray-400 dark:placeholder:text-gray-500 outline-none"
                        placeholder="Title"
                        v-model="updateYachtViewModel.performaceDetails.dimensions[index].title"
                        required
                      />
                    </div>
                    <div
                      class="whitespace-nowrap border-b-2 border-gray-200 dark:border-gray-700 box-border"
                    >
                      <input
                        type="text"
                        class="overflow-hidden flex px-3 py-4 w-full h-full text-black dark:text-white bg-transparent placeholder:text-gray-400 dark:placeholder:text-gray-500 outline-none"
                        placeholder="Type"
                        v-model="updateYachtViewModel.performaceDetails.dimensions[index].value1"
                        required
                      />
                    </div>
                    <div
                      class="whitespace-nowrap border-b-2 border-gray-200 dark:border-gray-700 box-border"
                    >
                      <input
                        type="text"
                        class="overflow-hidden flex px-3 py-4 w-full h-full text-black dark:text-white bg-transparent placeholder:text-gray-400 dark:placeholder:text-gray-500 outline-none"
                        placeholder="Value"
                        v-model="updateYachtViewModel.performaceDetails.dimensions[index].value2"
                        required
                      />
                    </div>
                  </div>
                </div>

                <div class="flex justify-center">
                  <button
                    type="button"
                    @click="() => addItemToPerformanceDetails('dimensions')"
                    class="text-2xl text-white bg-primary rounded-md hover:bg-primary/80 transition-all duration-300 focus:outline-none py-2 px-6 w-fit"
                  >
                    <Icon icon="ic:outline-plus"></Icon>
                  </button>
                </div>
              </div>

              <!-- Prformance -->
              <div class="flex flex-col gap-2 max-sm:gap-3">
                <div class="flex flex-col gap-2 w-full">
                  <h6
                    class="font-medium text-sm max-sm:text-base max-sm:font-semibold text-gray-600 dark:text-gray-400"
                  >
                    Prformance
                  </h6>
                  <input
                    type="text"
                    class="border-2 rounded-md overflow-hidden border-gray-200 dark:border-gray-700 box-border flex p-3 w-full text-black dark:text-white bg-gray-100 placeholder:text-gray-400 dark:placeholder:text-gray-500 dark:bg-gray-900 outline-none"
                    placeholder="Performance Description"
                    v-model="
                      updateYachtViewModel.performaceDetails
                        .performanceDescription
                    "
                    required
                  />
                </div>

                <div class="flex flex-col max-sm:hidden">
                  <div class="overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div class="inline-block min-w-full sm:px-6 lg:px-8">
                      <div
                        class="overflow-hidden border-2 rounded-md border-gray-200 dark:border-gray-700 box-border bg-gray-100 dark:bg-gray-900"
                      >
                        <table
                          class="min-w-full text-center text-sm font-light"
                        >
                          <thead
                            class="border-b-2 border-gray-200 dark:border-gray-700 box-border font-medium text-gray-600 dark:text-gray-400"
                          >
                            <tr>
                              <th
                                scope="col"
                                class="border-r-2 px-1 max-md:px-2 border-gray-200 dark:border-gray-700 box-border"
                              ></th>
                              <th
                                scope="col"
                                class="border-r-2 border-gray-200 dark:border-gray-700 box-border"
                              >
                                Title
                              </th>
                              <th
                                scope="col"
                                class="border-r-2 border-gray-200 dark:border-gray-700 box-border"
                              >
                                Type
                              </th>
                              <th scope="col" class="px-6 py-4">Value</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              :class="{
                                'border-b-2 border-gray-200 dark:border-gray-700 box-border':
                                  index !==
                                  updateYachtViewModel.performaceDetails.performances?.length - 1,
                              }"
                              v-for="(
                                item, index
                              ) in updateYachtViewModel.performaceDetails.performances"
                            >
                              <td
                                class="whitespace-nowrap px-1 max-md:px-2 border-r-2 border-gray-200 dark:border-gray-700 box-border font-medium"
                              >
                                <button
                                  @click="
                                    () =>
                                      deletePerformanceDetailsItem(
                                        index,
                                        'performances'
                                      )
                                  "
                                  :class="{
                                    'text-primary/50 border-primary/50':
                                      updateYachtViewModel.performaceDetails.performances
                                        ?.length == 1,
                                  }"
                                  :disabled="
                                    updateYachtViewModel.performaceDetails.performances?.length ==
                                    1
                                  "
                                  class="text-primary text-base border border-primary rounded-full p-1"
                                  type="button"
                                >
                                  <Icon icon="tabler:trash-filled" />
                                </button>
                              </td>
                              <td
                                class="whitespace-nowrap border-r-2 border-gray-200 dark:border-gray-700 box-border"
                              >
                                <input
                                  type="text"
                                  class="overflow-hidden flex px-3 py-4 w-full h-full text-black dark:text-white bg-transparent placeholder:text-gray-400 dark:placeholder:text-gray-500 outline-none"
                                  placeholder="Title..."
                                  v-model="
                                    updateYachtViewModel.performaceDetails.performances[index]
                                      .title
                                  "
                                  required
                                />
                              </td>
                              <td
                                class="whitespace-nowrap border-r-2 border-gray-200 dark:border-gray-700 box-border"
                              >
                                <input
                                  type="text"
                                  class="overflow-hidden flex px-3 py-4 w-full h-full text-black dark:text-white bg-transparent placeholder:text-gray-400 dark:placeholder:text-gray-500 outline-none"
                                  placeholder="Type..."
                                  v-model="
                                    updateYachtViewModel.performaceDetails.performances[index]
                                      .value1
                                  "
                                  required
                                />
                              </td>
                              <td class="whitespace-nowrap">
                                <input
                                  type="text"
                                  class="overflow-hidden flex px-3 py-4 w-full h-full text-black dark:text-white bg-transparent placeholder:text-gray-400 dark:placeholder:text-gray-500 outline-none"
                                  placeholder="Value..."
                                  v-model="
                                    updateYachtViewModel.performaceDetails.performances[index]
                                      .value2
                                  "
                                  required
                                />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  class="hidden max-sm:flex flex-col gap-2"
                  v-for="(item, index) in updateYachtViewModel.performaceDetails.performances"
                  :key="index"
                >
                  <div class="flex justify-between items-end">
                    <h6
                      class="font-medium text-sm text-gray-600 dark:text-gray-400"
                    >
                      {{ `Table Row ${index + 1}` }}
                    </h6>

                    <button
                      v-if="updateYachtViewModel.performaceDetails.performances?.length > 1"
                      @click="
                        () =>
                          deletePerformanceDetailsItem(index, 'performances')
                      "
                      class="text-primary text-base border border-primary rounded-full p-1"
                      type="button"
                    >
                      <Icon icon="tabler:trash-filled" />
                    </button>
                  </div>
                  <div
                    class="border-2 rounded-md border-gray-200 dark:border-gray-700 box-border bg-gray-100 dark:bg-gray-900"
                  >
                    <div
                      class="whitespace-nowrap border-b-2 border-gray-200 dark:border-gray-700 box-border"
                    >
                      <input
                        type="text"
                        class="overflow-hidden flex px-3 py-4 w-full h-full text-black dark:text-white bg-transparent placeholder:text-gray-400 dark:placeholder:text-gray-500 outline-none"
                        placeholder="Title"
                        v-model="updateYachtViewModel.performaceDetails.performances[index].title"
                        required
                      />
                    </div>
                    <div
                      class="whitespace-nowrap border-b-2 border-gray-200 dark:border-gray-700 box-border"
                    >
                      <input
                        type="text"
                        class="overflow-hidden flex px-3 py-4 w-full h-full text-black dark:text-white bg-transparent placeholder:text-gray-400 dark:placeholder:text-gray-500 outline-none"
                        placeholder="Type"
                        v-model="
                          updateYachtViewModel.performaceDetails.performances[index].value1
                        "
                        required
                      />
                    </div>
                    <div
                      class="whitespace-nowrap border-b-2 border-gray-200 dark:border-gray-700 box-border"
                    >
                      <input
                        type="text"
                        class="overflow-hidden flex px-3 py-4 w-full h-full text-black dark:text-white bg-transparent placeholder:text-gray-400 dark:placeholder:text-gray-500 outline-none"
                        placeholder="Value"
                        v-model="
                          updateYachtViewModel.performaceDetails.performances[index].value2
                        "
                        required
                      />
                    </div>
                  </div>
                </div>

                <div class="flex justify-center">
                  <button
                    type="button"
                    @click="() => addItemToPerformanceDetails('performances')"
                    class="text-2xl text-white bg-primary rounded-md hover:bg-primary/80 transition-all duration-300 focus:outline-none py-2 px-6 w-fit"
                  >
                    <Icon icon="ic:outline-plus"></Icon>
                  </button>
                </div>
              </div>

              <!-- Population System -->
              <div class="flex flex-col gap-2 max-sm:gap-3">
                <div class="flex flex-col gap-2 w-full">
                  <h6
                    class="font-medium text-sm max-sm:text-base max-sm:font-semibold text-gray-600 dark:text-gray-400"
                  >
                    Population System
                  </h6>
                  <input
                    type="text"
                    class="border-2 rounded-md overflow-hidden border-gray-200 dark:border-gray-700 box-border flex p-3 w-full text-black dark:text-white bg-gray-100 placeholder:text-gray-400 dark:placeholder:text-gray-500 dark:bg-gray-900 outline-none"
                    placeholder="Population System Description"
                    v-model="
                      updateYachtViewModel.performaceDetails
                        .populationSystemDescription
                    "
                    required
                  />
                </div>

                <div class="flex flex-col max-sm:hidden">
                  <div class="overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div class="inline-block min-w-full sm:px-6 lg:px-8">
                      <div
                        class="overflow-hidden border-2 rounded-md border-gray-200 dark:border-gray-700 box-border bg-gray-100 dark:bg-gray-900"
                      >
                        <table
                          class="min-w-full text-center text-sm font-light"
                        >
                          <thead
                            class="border-b-2 border-gray-200 dark:border-gray-700 box-border font-medium text-gray-600 dark:text-gray-400"
                          >
                            <tr>
                              <th
                                scope="col"
                                class="border-r-2 px-1 max-md:px-2 border-gray-200 dark:border-gray-700 box-border"
                              ></th>
                              <th
                                scope="col"
                                class="border-r-2 border-gray-200 dark:border-gray-700 box-border"
                              >
                                Title
                              </th>
                              <th
                                scope="col"
                                class="border-r-2 border-gray-200 dark:border-gray-700 box-border"
                              >
                                Type
                              </th>
                              <th scope="col" class="px-6 py-4">Value</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              :class="{
                                'border-b-2 border-gray-200 dark:border-gray-700 box-border':
                                  index !==
                                  updateYachtViewModel.performaceDetails.populationSystems
                                    ?.length -
                                    1,
                              }"
                              v-for="(
                                item, index
                              ) in updateYachtViewModel.performaceDetails.populationSystems"
                            >
                              <td
                                class="whitespace-nowrap px-1 max-md:px-2 border-r-2 border-gray-200 dark:border-gray-700 box-border font-medium"
                              >
                                <button
                                  @click="
                                    () =>
                                      deletePerformanceDetailsItem(
                                        index,
                                        'populationSystems'
                                      )
                                  "
                                  :class="{
                                    'text-primary/50 border-primary/50':
                                      updateYachtViewModel.performaceDetails.populationSystems
                                        ?.length == 1,
                                  }"
                                  :disabled="
                                    updateYachtViewModel.performaceDetails.populationSystems
                                      ?.length == 1
                                  "
                                  class="text-primary text-base border border-primary rounded-full p-1"
                                  type="button"
                                >
                                  <Icon icon="tabler:trash-filled" />
                                </button>
                              </td>
                              <td
                                class="whitespace-nowrap border-r-2 border-gray-200 dark:border-gray-700 box-border"
                              >
                                <input
                                  type="text"
                                  class="overflow-hidden flex px-3 py-4 w-full h-full text-black dark:text-white bg-transparent placeholder:text-gray-400 dark:placeholder:text-gray-500 outline-none"
                                  placeholder="Title..."
                                  v-model="
                                    updateYachtViewModel.performaceDetails.populationSystems[
                                      index
                                    ].title
                                  "
                                  required
                                />
                              </td>
                              <td
                                class="whitespace-nowrap border-r-2 border-gray-200 dark:border-gray-700 box-border"
                              >
                                <input
                                  type="text"
                                  class="overflow-hidden flex px-3 py-4 w-full h-full text-black dark:text-white bg-transparent placeholder:text-gray-400 dark:placeholder:text-gray-500 outline-none"
                                  placeholder="Type..."
                                  v-model="
                                    updateYachtViewModel.performaceDetails.populationSystems[
                                      index
                                    ].value1
                                  "
                                  required
                                />
                              </td>
                              <td class="whitespace-nowrap">
                                <input
                                  type="text"
                                  class="overflow-hidden flex px-3 py-4 w-full h-full text-black dark:text-white bg-transparent placeholder:text-gray-400 dark:placeholder:text-gray-500 outline-none"
                                  placeholder="Value..."
                                  v-model="
                                    updateYachtViewModel.performaceDetails.populationSystems[
                                      index
                                    ].value2
                                  "
                                  required
                                />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  class="hidden max-sm:flex flex-col gap-2"
                  v-for="(
                    item, index
                  ) in updateYachtViewModel.performaceDetails.populationSystems"
                  :key="index"
                >
                  <div class="flex justify-between items-end">
                    <h6
                      class="font-medium text-sm text-gray-600 dark:text-gray-400"
                    >
                      {{ `Table Row ${index + 1}` }}
                    </h6>

                    <button
                      v-if="updateYachtViewModel.performaceDetails.populationSystems?.length > 1"
                      @click="
                        () =>
                          deletePerformanceDetailsItem(
                            index,
                            'populationSystems'
                          )
                      "
                      class="text-primary text-base border border-primary rounded-full p-1"
                      type="button"
                    >
                      <Icon icon="tabler:trash-filled" />
                    </button>
                  </div>
                  <div
                    class="border-2 rounded-md border-gray-200 dark:border-gray-700 box-border bg-gray-100 dark:bg-gray-900"
                  >
                    <div
                      class="whitespace-nowrap border-b-2 border-gray-200 dark:border-gray-700 box-border"
                    >
                      <input
                        type="text"
                        class="overflow-hidden flex px-3 py-4 w-full h-full text-black dark:text-white bg-transparent placeholder:text-gray-400 dark:placeholder:text-gray-500 outline-none"
                        placeholder="Title"
                        v-model="
                          updateYachtViewModel.performaceDetails.populationSystems[index].title
                        "
                        required
                      />
                    </div>
                    <div
                      class="whitespace-nowrap border-b-2 border-gray-200 dark:border-gray-700 box-border"
                    >
                      <input
                        type="text"
                        class="overflow-hidden flex px-3 py-4 w-full h-full text-black dark:text-white bg-transparent placeholder:text-gray-400 dark:placeholder:text-gray-500 outline-none"
                        placeholder="Type"
                        v-model="
                          updateYachtViewModel.performaceDetails.populationSystems[index].value1
                        "
                        required
                      />
                    </div>
                    <div
                      class="whitespace-nowrap border-b-2 border-gray-200 dark:border-gray-700 box-border"
                    >
                      <input
                        type="text"
                        class="overflow-hidden flex px-3 py-4 w-full h-full text-black dark:text-white bg-transparent placeholder:text-gray-400 dark:placeholder:text-gray-500 outline-none"
                        placeholder="Value"
                        v-model="
                          updateYachtViewModel.performaceDetails.populationSystems[index].value2
                        "
                        required
                      />
                    </div>
                  </div>
                </div>

                <div class="flex justify-center">
                  <button
                    type="button"
                    @click="
                      () => addItemToPerformanceDetails('populationSystems')
                    "
                    class="text-2xl text-white bg-primary rounded-md hover:bg-primary/80 transition-all duration-300 focus:outline-none py-2 px-6 w-fit"
                  >
                    <Icon icon="ic:outline-plus"></Icon>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <!-- Gallery Section -->
          <div class="flex flex-col gap-4">
            <div class="flex flex-col gap-1 mb-1">
              <h3 class="text-lg text-primary font-medium">Gallery Section</h3>
              <hr
                class="border-none w-full h-[2px] bg-gray-200 dark:bg-gray-600"
              />
            </div>
            <div class="flex flex-col gap-2 w-full">
              <h6 class="font-medium text-sm text-gray-600 dark:text-gray-400">
                Gallery Pictures
              </h6>

              <FileUploader
                ref="yachtGalleryFilePound"
                @updatePendingFiles="updatePendingFiles($event, 'galleries')"
                :allowMultiple="true"
              ></FileUploader>
              <span class="italic text-sm text-black dark:text-white"
                >* Notice: You can select multiple images for the gallery
                section by holding the 'Ctrl' key.</span
              >
            </div>
          </div>

          <!-- Maps Section -->
          <div class="flex flex-col gap-4">
            <div class="flex flex-col gap-1 mb-1">
              <h3 class="text-lg text-primary font-medium">Maps Section</h3>
              <hr
                class="border-none w-full h-[2px] bg-gray-200 dark:bg-gray-600"
              />
            </div>
            <div class="flex max-xl:flex-col xl:gap-5 gap-4">
              <div class="flex flex-col gap-2 w-full">
                <h6
                  class="font-medium text-sm text-gray-600 dark:text-gray-400"
                >
                  Map 1
                </h6>
                <FileUploader
                  ref="mapPictureFilePound1"
                  @updatePendingFiles="updatePendingFiles($event, 'map1')"
                ></FileUploader>
              </div>

              <div class="flex flex-col gap-2 w-full">
                <h6
                  class="font-medium text-sm text-gray-600 dark:text-gray-400"
                >
                  Map 2
                </h6>
                <FileUploader
                  ref="mapPictureFilePound2"
                  @updatePendingFiles="updatePendingFiles($event, 'map2')"
                ></FileUploader>
              </div>
            </div>
          </div>

          <!-- Our Project Section -->
          <div class="flex flex-col gap-4">
            <div class="flex flex-col gap-1 mb-1">
              <h3 class="text-lg text-primary font-medium">Our Project</h3>
              <hr
                class="border-none w-full h-[2px] bg-gray-200 dark:bg-gray-600"
              />
            </div>
            <div class="grid md:grid-cols-3 grid-cols-1 md:gap-5 gap-3">
              <Input
                type="text"
                placeholder="Length"
                v-model="updateYachtViewModel.yachtLength"
                :isRequired="true"
              ></Input>

              <Input
                type="text"
                placeholder="Max Speed"
                v-model="updateYachtViewModel.maxSpeed"
                :isRequired="true"
              ></Input>

              <Input
                type="text"
                placeholder="Main Engine"
                v-model="updateYachtViewModel.mainEngine"
                :isRequired="true"
              ></Input>
            </div>
          </div>
        </div>

        <hr class="w-full border dark:border-gray-700" />
        <div class="wrapper-button flex justify-end mt-5">
          <button
            v-if="!isAdding"
            type="submit"
            class="text-white bg-primary rounded-md hover:bg-primary/80 transition-all duration-300 focus:outline-none py-2 px-6 w-fit"
          >
            Save
          </button>

          <button
            v-else
            disabled
            type="button"
            class="text-white bg-primary rounded-md opacity-60 cursor-default transition-all duration-300 focus:outline-none py-2 px-6 w-fit"
          >
            <svg
              role="status"
              class="inline w-4 h-4 mr-1 text-white animate-spin"
              viewBox="0 0 100 101"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                fill="#E5E7EB"
              />
              <path
                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                fill="currentColor"
              />
            </svg>
            Uploading...
          </button>
        </div>
      </form>
    </div>

    <div
      class="flex justify-center items-center w-full h-full p-16 pt-[140px]"
      v-if="isLoading"
    >
      <LoadingSpinner class="text-logicaTertiary dark:text-logicaSecondary" />
    </div>
  </div>
</template>

<script>
import FileUploader from "@/components/FileUploader.vue";
import axios from "axios";
import { Icon } from "@iconify/vue";
import Input from "@/components/Input.vue";
import TextEditor from "@/components/TextEditor.vue";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import settings from "@/settings/appSettings";
import router from '@/router';

export default {
  components: {
    FileUploader,
    Input,
    TextEditor,
    Icon,
    LoadingSpinner,
  },

  data() {
    return {
      isLoading: true,
      updateYachtViewModel: {
        id: null,
        projectImage: null,
        featuresBoxImage1: null,
        featuresBoxImage2: null,
        featuresBoxImage3: null,
        map1: null,
        map2: null,
        performaceDetails: {
          dimensions: [
            {
              title: "",
              value1: "",
              value2: "",
            },
          ],
          performances: [
            {
              title: "",
              value1: "",
              value2: "",
            },
          ],
          populationSystems: [
            {
              title: "",
              value1: "",
              value2: "",
            },
          ],
        },

        galleries: [],
        yachtSliders: [{ file: null, title: "" }],
        title: "",
        projectText: "",
        performanceDetailsDescription: "",
        featuresBoxes: {
          bestFeaturesBoxOneTitle: "",
          bestFeaturesBoxOneDescription: "",
          bestFeaturesBoxTwoTitle: "",
          bestFeaturesBoxTwoDescription: "",
          bestFeaturesBoxThreeTitle: "",
          bestFeaturesBoxThreeDescription: "",
        },
        yachtLength: "",
        maxSpeed: "",
        mainEngine: "",
        performaceDetails: {
          dimensionDescription: "",
          performanceDescription: "",
          populationSystemDescription: "",
        },
      },
      isAdding: false,
    };
  },

  async mounted() {
    this.updateYachtViewModel.id = this.$route.params.id;

    await this.getYacht(this.updateYachtViewModel.id);
  },

  methods: {
    updatePendingFiles: function (files, imageType, sliderIndex = 0) {
      console.log(files);
      if (files) {
        if (imageType == "featuresBoxImage1") {
          this.updateYachtViewModel.featuresBoxImage1 = files[0]?.file;
        }

        if (imageType == "featuresBoxImage2") {
          this.updateYachtViewModel.featuresBoxImage2 = files[0]?.file;
        }

        if (imageType == "featuresBoxImage3") {
          this.updateYachtViewModel.featuresBoxImage3 = files[0]?.file;
        }

        if (imageType == "map1") {
          this.updateYachtViewModel.map1 = files[0]?.file;
        }

        if (imageType == "map2") {
          this.updateYachtViewModel.map2 = files[0]?.file;
        }

        if (imageType == "slider") {
          this.updateYachtViewModel.yachtSliders[sliderIndex].file =
            files[0]?.file;
        }

        if (imageType == "projectImage") {
          this.updateYachtViewModel.projectImage = files[0]?.file;
        }

        if (imageType == "galleries") {
          this.updateYachtViewModel.galleries = files;
        }
      }
    },
    addItemToPerformanceDetails: function (type) {
      if (type == "dimensions") {
        this.updateYachtViewModel.performaceDetails.dimensions.push({
          title: "",
          value1: "",
          value2: "",
        });
      }

      if (type == "performances") {
        this.updateYachtViewModel.performaceDetails.performances.push({
          title: "",
          value1: "",
          value2: "",
        });
      }

      if (type == "populationSystems") {
        this.updateYachtViewModel.performaceDetails.populationSystems.push({
          title: "",
          value1: "",
          value2: "",
        });
      }
    },
    addSlider: function () {
      this.updateYachtViewModel.yachtSliders.push({ file: null, title: "" });
    },
    updateYacht: async function () {
      console.log(this.updateYachtViewModel);

      var bodyFormData = new FormData();

      bodyFormData.append("Id", this.updateYachtViewModel.id);

      for (
        let index = 0;
        index < this.updateYachtViewModel.yachtSliders?.length;
        index++
      ) {
        const element = this.updateYachtViewModel.yachtSliders[index];

        if (!element.file) {
          this.$store.commit("showToast", {
            id: "myToast",
            type: "error",
            title: `Slider Image ${index + 1} Is Required!`,
            body: `You must choice any image for slider image ${index + 1}!`,
          });
          return;
        }

        bodyFormData.append(
          "SliderViewModel.ImageFiles",
          element.file,
          element.file.name
        );

        bodyFormData.append("SliderViewModel.Descriptions", element.description);
      }

      bodyFormData.append("Title", this.updateYachtViewModel.title);
      bodyFormData.append("ProjectText", this.updateYachtViewModel.projectText);

      bodyFormData.append(
        "BestFeaturesBoxOneTitle",
        this.updateYachtViewModel.featuresBoxes.bestFeaturesBoxOneTitle
      );

      bodyFormData.append(
        "BestFeaturesBoxOneDescription",
        this.updateYachtViewModel.featuresBoxes.bestFeaturesBoxOneDescription
      );

      bodyFormData.append(
        "BestFeaturesBoxTwoTitle",
        this.updateYachtViewModel.featuresBoxes.bestFeaturesBoxTwoTitle
      );

      bodyFormData.append(
        "BestFeaturesBoxTwoDescription",
        this.updateYachtViewModel.featuresBoxes.bestFeaturesBoxTwoDescription
      );

      bodyFormData.append(
        "BestFeaturesBoxThreeTitle",
        this.updateYachtViewModel.featuresBoxes.bestFeaturesBoxThreeTitle
      );

      bodyFormData.append(
        "BestFeaturesBoxThreeDescription",
        this.updateYachtViewModel.featuresBoxes.bestFeaturesBoxThreeDescription
      );

      bodyFormData.append(
        "PerformanceDetailsDescription",
        this.updateYachtViewModel.performanceDetailsDescription
      );

      bodyFormData.append(
        "DimensionDescription",
        this.updateYachtViewModel.performaceDetails.dimensionDescription
      );

      bodyFormData.append(
        "PerformanceDescription",
        this.updateYachtViewModel.performaceDetails.performanceDescription
      );

      bodyFormData.append(
        "PopulationSystemDescription",
        this.updateYachtViewModel.performaceDetails.populationSystemDescription
      );

      bodyFormData.append("Length", this.updateYachtViewModel.yachtLength);

      bodyFormData.append("MaxSpeed", this.updateYachtViewModel.maxSpeed);

      bodyFormData.append("MainEngine", this.updateYachtViewModel.mainEngine);

      if (!this.updateYachtViewModel.projectImage) {
        this.$store.commit("showToast", {
          id: "myToast",
          type: "error",
          title: "Project Image Is Required!",
          body: "You must choice any image for project image!",
        });
        return;
      }

      bodyFormData.append(
        "ProjectImageFile",
        this.updateYachtViewModel.projectImage,
        this.updateYachtViewModel.projectImage.name
      );

      this.updateYachtViewModel.performaceDetails?.dimensions.forEach((item) => {
        bodyFormData.append("Dimensions", JSON.stringify(item));
      });

      this.updateYachtViewModel.performaceDetails?.performances.forEach((item) => {
        bodyFormData.append("Performances", JSON.stringify(item));
      });

      this.updateYachtViewModel.performaceDetails?.populationSystems.forEach((item) => {
        bodyFormData.append("PopulationSystems", JSON.stringify(item));
      });

      if (!this.updateYachtViewModel.featuresBoxImage1) {
        this.$store.commit("showToast", {
          id: "myToast",
          type: "error",
          title: "Features Box Image 1 Is Required!",
          body: "You must choice any image for features box image 1!",
        });
        return;
      }

      bodyFormData.append(
        "BestFeaturesBoxOneImageFile",
        this.updateYachtViewModel.featuresBoxImage1,
        this.updateYachtViewModel.featuresBoxImage1.name
      );

      if (!this.updateYachtViewModel.featuresBoxImage2) {
        this.$store.commit("showToast", {
          id: "myToast",
          type: "error",
          title: "Features Box Image 2 Is Required!",
          body: "You must choice any image for features box image 2!",
        });
        return;
      }

      bodyFormData.append(
        "BestFeaturesBoxTwoImageFile",
        this.updateYachtViewModel.featuresBoxImage2,
        this.updateYachtViewModel.featuresBoxImage2.name
      );

      if (!this.updateYachtViewModel.featuresBoxImage3) {
        this.$store.commit("showToast", {
          id: "myToast",
          type: "error",
          title: "Features Box Image 3 Is Required!",
          body: "You must choice any image for features box image 3!",
        });
        return;
      }

      bodyFormData.append(
        "BestFeaturesBoxThreeImageFile",
        this.updateYachtViewModel.featuresBoxImage3,
        this.updateYachtViewModel.featuresBoxImage3.name
      );

      if (this.updateYachtViewModel.galleries?.length == 0) {
        this.$store.commit("showToast", {
          id: "myToast",
          type: "error",
          title: `Gallery Image Is Required!`,
          body: "You must choice one image at least for gallery images!",
        });
        return;
      }

      this.updateYachtViewModel.galleries.forEach((element) => {
        bodyFormData.append(
          "GalleryViewModel.Files",
          element.file,
          element.file.name
        );
      });

      if (!this.updateYachtViewModel.map1) {
        this.$store.commit("showToast", {
          id: "myToast",
          type: "error",
          title: "Map Image 1 Is Required!",
          body: "You must choice any image for map image 1!",
        });
        return;
      }

      bodyFormData.append(
        "MapPictureFile1",
        this.updateYachtViewModel.map1,
        this.updateYachtViewModel.map1.name
      );

      if (!this.updateYachtViewModel.map2) {
        this.$store.commit("showToast", {
          id: "myToast",
          type: "error",
          title: "Map Image 2 Is Required!",
          body: "You must choice any image for map image 2!",
        });
        return;
      }

      bodyFormData.append(
        "MapPictureFile2",
        this.updateYachtViewModel.map2,
        this.updateYachtViewModel.map2.name
      );

      this.isAdding = true;

      let options = {
        headers: {
          "Content-Type": "multipart/form-data",
          isNeedJwtAuth: true,
        },
      };

      await axios
        .put("Yachts", bodyFormData, options)
        .then((response) => {
          this.isAdding = false;

          console.log(response);

          window.scrollTo(0, 0);

          this.$store.commit("showToast", {
            id: "myToast",
            type: "success",
            title: "Update Successful!",
            body: "The update was successful!",
          });
        })
        .catch((error) => {
          this.isAdding = false;
          if (error.response?.data?.isSuccess == false) {
            this.$store.commit("showToast", {
              id: "myToast",
              type: "error",
              title: "Add Failed!",
              body: error.response?.data?.messages[0],
            });
          }
          console.log(error);
          console.log(error.response);
        });
    },
    deleteSliderItem(index) {
      this.updateYachtViewModel.yachtSliders.splice(index, 1);
    },
    deletePerformanceDetailsItem(index, type) {
      if (type == "dimensions") {
        this.updateYachtViewModel.performaceDetails.dimensions.splice(index, 1);
      } else if (type == "performances") {
        this.updateYachtViewModel.performaceDetails.performances.splice(index, 1);
      } else if (type == "populationSystems") {
        this.updateYachtViewModel.performaceDetails.populationSystems.splice(index, 1);
      }
    },
    async getYacht(id) {
      console.log("refs", this.$refs[`yachtSliderImageFilePound${0}`]);
      await axios
        .get(`Yachts/${id}`)
        .then((response) => {
          if (response.data?.value.projectImageUrl) {
            this.$refs.projectImageFilePound.refPound.addFile(
              `${settings.backendBaseUrl}/${response.data?.value.projectImageUrl}?_`
            );
          }

          if (response.data?.value.mapPictureUrl1) {
            this.$refs.mapPictureFilePound1.refPound.addFile(
              `${settings.backendBaseUrl}/${response.data?.value.mapPictureUrl1}?_`
            );
          }

          if (response.data?.value.mapPictureUrl2) {
            this.$refs.mapPictureFilePound2.refPound.addFile(
              `${settings.backendBaseUrl}/${response.data?.value.mapPictureUrl2}?_`
            );
          }

          if (response.data?.value.featuresBoxes?.bestFeaturesBoxOneImageUrl) {
            this.$refs.bestFeaturesBoxOneImageFilePound.refPound.addFile(
              `${settings.backendBaseUrl}/${response.data?.value.featuresBoxes?.bestFeaturesBoxOneImageUrl}?_`
            );
          }

          if (response.data?.value.featuresBoxes?.bestFeaturesBoxTwoImageUrl) {
            this.$refs.bestFeaturesBoxTwoImageFilePound.refPound.addFile(
              `${settings.backendBaseUrl}/${response.data?.value.featuresBoxes?.bestFeaturesBoxTwoImageUrl}?_`
            );
          }

          if (
            response.data?.value.featuresBoxes?.bestFeaturesBoxThreeImageUrl
          ) {
            this.$refs.bestFeaturesBoxThreeImageFilePound.refPound.addFile(
              `${settings.backendBaseUrl}/${response.data?.value.featuresBoxes?.bestFeaturesBoxThreeImageUrl}?_`
            );
          }

          for (
            let index = 0;
            index < response.data?.value.yachtGallery?.length;
            index++
          ) {
            const current = response.data?.value.yachtGallery[index];

            if (current.fileUrl) {
              this.$refs.yachtGalleryFilePound.refPound.addFile(
                `${settings.backendBaseUrl}/${current.fileUrl}?_`
              );
            }
          }

          this.updateYachtViewModel = response.data?.value;

          this.updateYachtViewModel.id = id;

          setTimeout(() => {
            for (
              let index = 0;
              index < response.data?.value.yachtSliders.length;
              index++
            ) {
              const current = response.data?.value.yachtSliders[index];

              if (current.imageUrl) {
                console.log(
                  "koft",
                  this.$refs[`yachtSliderImageFilePound${index}`][0]
                );

                this.$refs[
                  `yachtSliderImageFilePound${index}`
                ][0].refPound.addFile(
                  `${settings.backendBaseUrl}/${current.imageUrl}?_`
                );
              }
            }
          }, 1000);

          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;

          if (error.response?.data?.isSuccess == false) {
            this.$store.commit("showToast", {
              id: "myToast",
              type: "error",
              title: "Error!",
              body: error.response.data.messages[0],
            });
          } else {
            this.$store.commit("showToast", {
              id: "myToast",
              type: "error",
              title: "Error!",
              body: "An unknown error occurred",
            });
          }

          router.push("/");
          console.log(error);
          console.log(error.response);
        });
    },
  },
};
</script>
