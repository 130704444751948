<template>
  <div class="w-full h-full flex items-center justify-center">
    <div class="space-y-3 max-w-lg p-5">
      <h2 class="dark:text-white font-semibold text-center text-black xs:text-4xl 2xs:text-3xl text-2xl">
        Welcome, to
      </h2>

      <div class="form-head cursor-pointer" @click="$router.push('/')">
        <img
          src="../assets/logo/logica-log-dark.png"
          alt="logo"
          class="w-full hidden dark:block"
        />
        <img
          src="../assets/logo/logica-log-light.png"
          alt="logo"
          class="w-full block dark:hidden"
        />
      </div>
    </div>
  </div>
</template>
