<script setup>
import { vOnClickOutside } from "@vueuse/components";
</script>

<template>
  <!-- sidebar -->
  <nav class="sidebar bg-white dark:bg-gray-800" v-on-click-outside="closeMenuOnOutside">
    <!-- sidebar head -->
    <div class="sidebar-head flex justify-between items-center gap-5 p-4 pt-7">
      <router-link to="/" exact class="flex max-w-[250px]">
        <img
          src="@/assets/logo/logica-log-dark.png"
          alt="logo logica"
          class="w-full hidden dark:block"
        />
        <img
          src="@/assets/logo/logica-log-light.png"
          alt="logo logica"
          class="w-full block dark:hidden"
        />
      </router-link>
      <button class="lg:hidden block dark:text-gray-400" @click="$emit('sidebarToggle')">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          aria-hidden="true"
          role="img"
          width="25px"
          height="25px"
          preserveAspectRatio="xMidYMid meet"
          viewBox="0 0 32 32"
        >
          <path
            fill="currentColor"
            d="M7.219 5.781L5.78 7.22L14.563 16L5.78 24.781l1.44 1.439L16 17.437l8.781 8.782l1.438-1.438L17.437 16l8.782-8.781L24.78 5.78L16 14.563z"
          />
        </svg>
      </button>
    </div>
    <!-- sidebar list -->
    <div
      class="sidebar-list overflow-y-auto h-[calc(100vh-(77.34px+1rem))] px-4 pb-4 mt-4 divide-y dark:divide-gray-700"
    >
      <div class="pb-5">
        <p class="font-medium text-gray-400 dark:text-gray-400">Menu</p>
        <div class="wrap-item mt-4 dark:text-gray-500">
          <div class="item">
            <router-link
              to="/"
              class="w-full flex text-left rounded-md box-border p-3 text-gray-800 dark:text-gray-500 hover:bg-gray-200 dark:hover:bg-gray-700"
            >
              <span class="mr-3 text-2xl"><Icon icon="material-symbols:home" /></span>
              <span class="w-full"> Home </span>
            </router-link>
          </div>
          <div class="item mt-3">
            <menu-accordion>
              <template v-slot:icon>
                <Icon icon="el:idea" />
              </template>
              <template v-slot:title> Concept </template>
              <template v-slot:content>
                <router-link
                  to="/concepts"
                  class="flex items-center gap-3 w-full text-left rounded-md p-3 hover:bg-gray-200 dark:hover:bg-gray-700"
                >
                  <span class="text-base"
                    ><Icon icon="material-symbols:arrow-right-alt-rounded"
                  /></span>
                  Concepts List
                </router-link>
                <router-link
                to="/concepts/add"
                  class="flex items-center gap-3 w-full text-left rounded-md p-3 hover:bg-gray-200 dark:hover:bg-gray-700"
                >
                  <span class="text-base"
                    ><Icon icon="material-symbols:arrow-right-alt-rounded"
                  /></span>
                  Add Concept
                </router-link>
              </template>
            </menu-accordion>
          </div>
          <div class="item mt-3">
            <menu-accordion>
              <template v-slot:icon>
                <Icon icon="fluent-emoji-high-contrast:ship" />
              </template>
              <template v-slot:title> Yachts </template>
              <template v-slot:content>
                <router-link
                  to="/yachts"
                  class="flex items-center gap-3 w-full text-left rounded-md p-3 hover:bg-gray-200 dark:hover:bg-gray-700"
                >
                  <span class="text-base"
                    ><Icon icon="material-symbols:arrow-right-alt-rounded"
                  /></span>
                  Yachts List
                </router-link>
                <router-link
                  to="/yachts/add"
                  class="flex items-center gap-3 w-full text-left rounded-md p-3 hover:bg-gray-200 dark:hover:bg-gray-700"
                >
                  <span class="text-base"
                    ><Icon icon="material-symbols:arrow-right-alt-rounded"
                  /></span>
                  Add Yacht
                </router-link>
              </template>
            </menu-accordion>
          </div>
          <div class="item mt-3">
            <menu-accordion>
              <template v-slot:icon>
                <Icon icon="dashicons:welcome-write-blog" />
              </template>
              <template v-slot:title> Press </template>
              <template v-slot:content>
                <div class="">
                  <router-link
                    to="/press"
                    class="flex items-center gap-3 w-full text-left rounded-md p-3 hover:bg-gray-200 dark:hover:bg-gray-700"
                  >
                    <span class="text-base"
                      ><Icon icon="material-symbols:arrow-right-alt-rounded"
                    /></span>
                    Press List
                  </router-link>
                  <router-link
                    to="/press/add"
                    class="flex items-center gap-3 w-full text-left rounded-md p-3 hover:bg-gray-200 dark:hover:bg-gray-700"
                  >
                    <span class="text-base"
                      ><Icon icon="material-symbols:arrow-right-alt-rounded"
                    /></span>
                    Add Press
                  </router-link>
                </div>
              </template>
            </menu-accordion>
          </div>
          <div class="item mt-3">
            <menu-accordion>
              <template v-slot:icon>
                <Icon icon="fluent:news-28-filled" />
              </template>
              <template v-slot:title> News </template>
              <template v-slot:content>
                <div>
                  <router-link
                    to="/news"
                    class="flex items-center gap-3 w-full text-left rounded-md p-3 hover:bg-gray-200 dark:hover:bg-gray-700"
                  >
                    <span class="text-base"
                      ><Icon icon="material-symbols:arrow-right-alt-rounded"
                    /></span>
                    News List
                  </router-link>

                  <router-link
                    to="/news/add"
                    class="flex items-center gap-3 w-full text-left rounded-md p-3 hover:bg-gray-200 dark:hover:bg-gray-700"
                  >
                    <span class="text-base"
                      ><Icon icon="material-symbols:arrow-right-alt-rounded"
                    /></span>
                    Add News
                  </router-link>
                </div>
              </template>
            </menu-accordion>
          </div>
          <div class="item mt-3">
            <router-link
              to="/contact"
              class="w-full flex text-left rounded-md box-border p-3 text-gray-800 dark:text-gray-500 hover:bg-gray-200 dark:hover:bg-gray-700"
            >
              <span class="mr-3 text-2xl"><Icon icon="material-symbols:mail" /></span>
              <span class="w-full"> Contact </span>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import { Icon } from "@iconify/vue";
import MenuAccordion from "./MenuAccordion.vue";
export default {
  components: {
    Icon,
    MenuAccordion,
  },
  methods: {
    closeMenuOnOutside() {
      this.$emit("closeMenuOnOutside");
    },
  },
};
</script>
