import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import VueApexCharts from "vue3-apexcharts";
import { createPinia } from "pinia";
// import "flowbite";
import "./assets/tailwind.css";
import "./assets/animate.css";
import "./assets/sass/css/dashboard.css";
import vClickOutside from "click-outside-vue3";

import setting from "./settings/appSettings"
import { toastStore } from '@/store/toastStore.js';
import { useUserSignData } from "@/store/userSignData";

import axios from "axios";

import {
  refreshTokenInterceptor,
  initialRefreshTokenInterceptor,
  jwtRequestInterceptor,
  initialLogOutMethod,
} from "./lib/axiosJwtInterceptors";

initialRefreshTokenInterceptor({
  refreshTokenUrl: `${setting.backendBaseUrl}/api/v1/Users/RefreshToken`,
});

axios.defaults.baseURL = `${setting.backendBaseUrl}/api/v1/`;

axios.interceptors.request.use(jwtRequestInterceptor, undefined);

axios.interceptors.response.use(
  refreshTokenInterceptor(axios),
  refreshTokenInterceptor(axios)
);

const app = createApp(App);
app.use(toastStore);
app.use(createPinia());

initialLogOutMethod(useUserSignData().logout)

app.use(router);
app.use(VueApexCharts);
app.use(vClickOutside);
app.mount("#app");
