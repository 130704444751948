import { createRouter, createWebHistory } from "vue-router";

// Default Pages
import Home from "../views/Home.vue";

// Login Page
import Login from "../views/Login.vue";

// News Pages
import NewsList from "../views/news/NewsList.vue";
import AddNews from "../views/news/AddNews.vue";
import EditNews from "../views/news/EditNews.vue";

// Press Pages
import PressList from "../views/press/PressList.vue";
import AddPress from "../views/press/AddPress.vue";

// Yachts Pages
import AddYacht from "../views/yachts/AddYacht.vue";
import EditYacht from "../views/yachts/EditYacht.vue";
import YachtsList from "../views/yachts/YachtsList.vue";

// Concept Pages
import AddConcept from "../views/concepts/AddConcept.vue";
import EditConcept from "../views/concepts/EditConcept.vue";
import ConceptsList from "../views/concepts/ConceptsList.vue";

// Contact Page
import Contact from "../views/Contact.vue";

// layouts
import Blank from "../views/layouts/Blank.vue";

// error page
import Page404 from "../views/layouts/error/404.vue";
import Page500 from "../views/layouts/error/500.vue";

var appname = " | LogicaYachts Dashboard";

import { useUserSignData } from "@/store/userSignData";

const routes = [
  // Routes
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: { title: "Home " + appname, homePage: true },
  },

  // News Routes
  {
    path: "/news",
    name: "NewsList",
    component: NewsList,
    meta: { title: "News" + appname },
  },
  {
    path: "/news/add",
    name: "AddNews",
    component: AddNews,
    meta: { title: "Add News" + appname },
  },
  {
    path: "/news/edit/:id",
    name: "EditNews",
    component: EditNews,
    meta: { title: "Edit News" + appname },
  },

  // Press Routes
  {
    path: "/press",
    name: "PressList",
    component: PressList,
    meta: { title: "Press" + appname },
  },
  {
    path: "/press/add",
    name: "AddPress",
    component: AddPress,
    meta: { title: "Add Press" + appname },
  },

  // Yachts Routes
  {
    path: "/yachts/add",
    name: "AddYacht",
    component: AddYacht,
    meta: { title: "Add Yacht" + appname },
  },
  {
    path: "/yachts/edit/:id",
    name: "EditYacht",
    component: EditYacht,
    meta: { title: "Edit Yacht" + appname },
  },
  {
    path: "/yachts",
    name: "YachtsList",
    component: YachtsList,
    meta: { title: "Press" + appname },
  },

  // Concept Route
  {
    path: "/concepts/add",
    name: "AddConcept",
    component: AddConcept,
    meta: { title: "Add Concept" + appname },
  },
  {
    path: "/concepts",
    name: "ConceptsList",
    component: ConceptsList,
    meta: { title: "Press" + appname },
  },
  ,
  {
    path: "/concepts/edit/:id",
    name: "EditConcept",
    component: EditConcept,
    meta: { title: "Edit Concept" + appname },
  },

  // Contact Route
  {
    path: "/contact",
    name: "Contact",
    component: Contact,
    meta: { title: "Contact" + appname },
  },

  // layouts
  {
    path: "/Blank",
    name: "Blank Page",
    component: Blank,
    meta: { title: "Blank Page" + appname },
  },

  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: { title: "Login" + appname, hideNav: true, loginPage: true },
  },

  // layout/error
  // default page 404
  {
    path: "/:pathMatch(.*)*",
    name: "Page404",
    component: Page404,
    meta: { title: "Upps! 404" + appname, hideNav: true },
  },
  {
    path: "/500",
    name: "Page500",
    component: Page500,
    meta: { title: "Server internal Error" + appname, hideNav: true },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,

  linkExactActiveClass: "exact-active",
});

router.beforeEach((to, from, next) => {
  const store = useUserSignData();

  console.log(store);

  document.title = to.meta.title;

  if (!store.isAuthenticated && to.path !== "/login") next("/login");
  else next();
});

export default router;
