<template>
  <button
    @click="toggleAccordion()"
    class="text-gray-800 relative z-[2] dark:text-gray-500  hover:bg-gray-200 dark:hover:bg-gray-700 w-full flex text-left rounded-md box-border p-3"
    :aria-expanded="isOpen"
    :class="{
      'bg-gray-200  dark:bg-gray-700': isOpen,
      'bg-transparent': !isOpen,
    }"
  >
    <span class="mr-3 text-2xl">
      <slot name="icon"></slot>
    </span>
    <span class="w-full">
      <slot name="title" />
    </span>
    <span
      class="box-border mt-1 text-gray-500 dark:text-gray-500"
      :class="{
        'rotate-180': isOpen,
        'rotate-0': !isOpen,
      }"
    >
      <span class="float-right">
        <Icon icon="fa6-solid:angle-down" />
      </span>
    </span>
  </button>

  <transition name="slide-down">
    <div
      class="dark:bg-gray-900 relative z-[1] bg-gray-300 rounded-md mt-2 p-2"
      v-show="isOpen"
    >
      <slot name="content" />
    </div>
  </transition>
</template>

<script>
import { Icon } from "@iconify/vue";
export default {
  data() {
    return {
      isOpen: false,
    };
  },

  methods: {
    toggleAccordion() {
      this.isOpen = !this.isOpen;
    },
  },
  components: {
    Icon,
  },
};
</script>
